import * as React from 'react';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="not-found-main text-center pt-6 pb-5 min-h-[600px] flex items-center justify-center">
        <div className="container">
          <h1 className="text-primary mb-2">404: Not Found</h1>
          <p className="md:text-24">
            You just hit a route that doesn&#39;t exist... the sadness.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
